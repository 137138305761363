#symptom-screener-container a {
  /*color: rgb(158,78,135); */
  color: #000000;
}

.hidden { visibility: hidden; }

.hidden-left {
  position: relative;
  right: 100%;
}

.symptom-screener-stories-table td {
  padding: 20px;
}

.symptom-screener-center {
  align-content: center;  
}

.symptom-screener-avatar {
  width: 75px;
  height: 100px;
  display: inline-block;
  float: left;
  position: absolute;
  background: no-repeat url('./gene.png');
  z-index: 1005;
}

.symptom-screener-dialogue-bubble {
  width: 90%;
  display: inline-block;
  margin-top: 15px;
  margin-left: 73px;
  color: #000000;
  border-radius: 20px;
  border: 1px solid #ccc;
  /*background-color: rgb(158,78,135, 0.15);*/
  background-color: rgb(202, 226, 242);
}

@media(max-width: 480px) {
  .symptom-screener-dialogue-bubble {
    width: 80%;
	background-color: #cae2f2;
  }
}

@media(min-width: 480px) {
  .symptom-screener-dialogue-item {  
    margin: 50px;
    margin-bottom: 70px;
    position: "relative";
  }  
}


@media(max-width: 480px) {
  .symptom-screener-dialogue-item {
    margin: 15px;
    margin-bottom: 35px;
    position: "relative";
  }
}

.symptom-screener-dialogue-item-completed {
  /*background-color: #efefef;*/
  background-color: #cae2f2;
  transition: background 0.5s linear;
  -webkit-transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
}

.symptom-screener-dialogue-item ul {
  text-decoration: none;
  list-style-type: none;
  text-align: center;
  margin-bottom: 10px;

}

.symptom-screener-dialogue-prompt {
  border-radius: 20px;
  margin: -1px -1px -1px -1px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  overflow-x: hidden;
}

.symptom-screener-dialogue-prompt p {
  margin: 20px;
  margin-bottom: 25px;
}

@media(max-width: 480px) {
  .symptom-screener-dialogue-prompt {
    font-size: 0.9em;
  }
  .symptom-screener-dialogue-prompt p {
    font-size: 1em;
  }
}

.symptom-screener-dialogue-subprompt {
  margin-top: 40px;
  margin-left: 20px;
  padding: 10px;
  overflow-x: hidden;
}

.symptom-screener-dialogue-subprompt p {
  font-size: 1em;
}

.symptom-screener-dialogue-options {
  margin-top: 20px;
  padding: 20px;
  display: none;
  background-color: #cae2f2;
}

.symptom-screener-dialogue-option-container {
  display: table;
  border-spacing: 10px;
  text-align: center;
}

.symptom-screener-dialogue-option-container-2w {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
}

.symptom-screener-dialogue-option-container-3w {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, auto);
  grid-auto-columns: 1fr;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
}

@media(min-width: 768px) {
  .symptom-screener-dialogue-option-container-3w-collapsible-1w-md {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, auto);
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }
}

@media(max-width: 768px) {
  .symptom-screener-dialogue-option-container-3w-collapsible-1w-md {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, auto);
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }
}

.symptom-screener-dialogue-option-container-4w {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  grid-gap: 10px;
  text-align: center;
  justify-content: center;
}

@media(min-width: 768px) {
  .symptom-screener-dialogue-option-container-4w-collapsible-1w-md {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }
}

@media(max-width: 768px) {
  .symptom-screener-dialogue-option-container-4w-collapsible-1w-md {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }
}
h4 {
  font-weight: 700;
}

th {
  padding: 10px;
  border: 1px solid #efefef;
}

tr {
  position: relative;
}

td {
  padding: 10px;
  border: 1px solid #efefef;
  vertical-align: middle;
}

.symptom-screener-tearsheet {
  margin: 25px 100px;
}


.symptom-screener-button-container {
    text-align: center;
}

.symptom-screener-print-button { 
    margin-bottom: 10px;
    background-color: rgb(158,78,135);
    color: #fff;
    border-radius: 5px;
    border: 0px;
    width: 180px;
    margin-right: 10px;
    font-size: 11px;
    font-weight: 700;
    text-align: left;
}
   
   .symptom-screener-button-div-1 { 
    width: 25px;
    display: inline-block;
   }
   
   .symptom-screener-button-div-2 { 
    width: 120px;
    display: inline-block;
    border-left: 1px solid #fff;
    padding: 5px 8px;
    
   }

  .symptom-screener-result-button { 
    margin-bottom: 10px;
    background-color: #aaa;
    color: #fff;
    border-radius: 5px;
    border: 0px;
    width: 180px;
    margin-right: 10px;
    font-size: 11px;
    font-weight: 700;
    text-align: left;
  }
  
  .symptom-screener-personalized-div {
    border: 1px solid #ccc; 
    padding-left: 20px; 
    background-color: #f3f4f4;

   }
  
  .symptom-screener-personalized-h3 {
   
    color: rgb(158,78,135);
    font-weight: 600;

  }

@media(min-width: 1024px) {
  .symptom-screener-dialogue-option-container-4w-collapsible-1w-lg {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }  
}

@media(max-width: 1024px) {
  .symptom-screener-dialogue-option-container-4w-collapsible-1w-lg {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-columns: 1fr;
    grid-gap: 10px;
    text-align: center;
    justify-content: center;
  }
}

.symptom-screener-dialogue-item-option {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  vertical-align: middle;
  background-color: #ffffff;
  overflow-x: hidden;
}

.symptom-screener-dialogue-item-option b {
  font-size: 16px;
}

.symptom-screener-dialogue-item-option p {
  font-size: 12px;
}

.symptom-screener-selected {
  /*border: 2px solid #9e4e87;
  background-color: rgb(158,78,135, 0.2);*/
  border: 3px solid #2c8ccc;
  background-color: #fff;
}

.symptom-screener-button {
  /*border: 2px solid #9e4e87;*/
  border: 2px solid #2c8ccc;
  border-radius: 5px;
  /*background-color: #9e4e87;*/
  background-color: #2c8ccc;
  color: #fff;
}

.symptom-screener-submit-button {
  text-align: center;
  margin-bottom: 50px;
}

.symptom-screener-button-null {
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: black;
}

.symptom-screener-paginated-container {

}

.symptom-screener-paginated-container-footer {
  
}

.symptom-screener-paginated-container-footer ul li {
  
}

.symptom-screener-paginated-container-footer-active {
  /*background-color: #9e4e87;*/
  background-color: #cae2f2;
  border-radius: 3px;
}

.symptom-screener-paginated-container-footer li {
  display: inline-block;
  margin-left: 5px;
  min-width: 20px;
}

.symptom-screener-paginated-container-footer-active a {
  color: #ffffff !important;
}

#symptom-screener-overlay {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0, 0.8);
}

#symptom-screener-container {
  background-color: #ffffff;
  height: 100%;
  width: 80%;
  margin: auto;
}

@media(max-width: 480px) {
  #symptom-screener-container {
    width: 100%;
  }
}

#symptom-screener-rehydration-prompt {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #ffffff;
  z-index: 1002;
  text-align: center;
  overflow-x: hidden;
}

#symptom-screener-header-container {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  width: 100%;
  height: 12%;
  background-color: #ffffff;
  /*border-bottom: 1px solid #9e4e87;*/
  border-bottom: 1px solid #2c8ccc;
}

#symptom-screener-header {
  text-align: center;
}

#symptom-screener-footer {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  text-align: center;
  background-color: #ffffff;
}

#symptom-screener-footer p {
  /*border-top: 2px solid #9e4e87;*/
  border-top: 2px solid #2c8ccc;
  padding: 10px;
  font-size: 0.7em;
  color: #000000;
}

#symptom-screener-dialogue {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f8f8f8;
}

#symptom-screener-container-close {
  position: absolute;
  display: inline;
  float: right;
  right: 10px;
}

#symptom-screener-title {
  display: inline-block;
}

@media(max-width: 480px) {
  #symptom-screener-title {
    display: block;
  }  
}

#spinner {
  border: 16px solid #f3f3f3;
  /*border-top: 16px solid #9e4e87; */
  border-top: 16px solid #2c8ccc;
  background-color: none;
  margin: auto;
  position: fixed;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

#symptom-screener-q8-footer {
  margin-top: 40px;
  margin-bottom: 40px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.off-left {
  position: absolute;
  left: -100% !important;
  -webkit-transition: left 1s ease-in-out;
  -moz-transition:    left 1s ease-in-out;
  -ms-transition:     left 1s ease-in-out;
  -o-transition:      left 1s ease-in-out;
  transition:         left 1s ease-in-out;
}

.off-right {
  position: absolute;
  left: 100% !important;
  -webkit-transition: left 1s ease-in-out;
  -moz-transition:    left 1s ease-in-out;
  -ms-transition:     left 1s ease-in-out;
  -o-transition:      left 1s ease-in-out;
  transition:         left 1s ease-in-out;
}

.off-bottom {
  position: absolute;
  bottom: -100% !important;
  -webkit-transition: bottom 1s ease-in-out;
  -moz-transition:    bottom 1s ease-in-out;
  -ms-transition:     bottom 1s ease-in-out;
  -o-transition:      bottom 1s ease-in-out;
  transition:         bottom 1s ease-in-out;
}

.off-top {
  position: absolute;
  bottom: 100% !important;
  -webkit-transition: bottom 1s ease-in-out;
  -moz-transition:    bottom 1s ease-in-out;
  -ms-transition:     bottom 1s ease-in-out;
  -o-transition:      bottom 1s ease-in-out;
  transition:         bottom 1s ease-in-out;
}

.left-justify-content {
  text-align: left;
}

@media(max-width: 480px) {
  #symptom-screener-dialogue-item-3-age {
    display: block;
  }
}

@media(max-width: 480px) {
  #symptom-screener-footer {
    font-size: 0.6em;
  }
}

@media print { 
  .printme  { display: block;}
}