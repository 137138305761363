label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.tg-cell {
  background-color: #f7f7f7;
  padding: 2px 3px 5px;
  margin: 0 auto 2px;
  margin-top: 5px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.tg-white-bg {
  background-color: #ffffff;
}
.tg-blue-bg {
  background-color: #2c8ccc;
}
.tg-query {
  background-color: #e9ecef;
}
.box {
  margin: 10px;
  padding: 20px;
  border: 2px solid salmon;
  text-align: center;
  color: salmon;
  background-color: #e9ecef;
}
.btn-primary {
  background-color: #2c8ccc !important;
}
.tg-cell {
  font-size: 12px;
}
.tg-row {
}
.tg-row.Mui-selected {
  /* Increase the specificity */
  background-color: #e9ecef !important;
}
.tg-checkbox {
}
.tg-checkbox.Mui-selected {
  color: #2c8ccc !important;
  background-color: #e9ecef !important;
}
.tg-checkbox.Mui-checked {
  color: #2c8ccc !important;
  background-color: #e9ecef !important;
}
